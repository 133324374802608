import { useState } from 'react'
import { useCreateEmailCampaignTextMutation } from '@sevenrooms/core/api'
import type { GroupVenue, VenueUserProfile } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useHistory } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
  Tabs,
  TabPanel,
  Tab,
  TabList,
  Loader,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { emailBuilderMessages } from '../../../../locales'
import { Prompt } from './PromptForm'
import { Template } from './TemplateForm'

export interface TemplateForm {
  prompt: string
  callToAction: string
  tone: string
  style: string
  sender: string
  customUser: string
  crossPromoteVenue: string
}
export interface PromptForm {
  prompt: string
  callToAction: string
  sender: string
  customUser: string
}
interface AssistantFormModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onGenerateClick: Function
  setTemplateForm: React.Dispatch<React.SetStateAction<TemplateForm>>
  templateForm: TemplateForm
  setPromptForm: React.Dispatch<React.SetStateAction<PromptForm>>
  promptForm: PromptForm
  groupVenues: GroupVenue[]
  setIsTemplateFormSelected: Function
  venueUsers: VenueUserProfile[]
}

export function AssistantFormModal({
  closeHref,
  onGenerateClick,
  setTemplateForm,
  templateForm,
  setPromptForm,
  promptForm,
  groupVenues,
  setIsTemplateFormSelected,
  venueUsers,
}: AssistantFormModalProps) {
  const { formatMessage } = useLocales()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const history = useHistory()
  const { venueId } = useVenueContext()
  const [generateEmailCampaign, { isLoading }] = useCreateEmailCampaignTextMutation()
  const tabs: { key: string; title: string }[] = [
    {
      key: 'template',
      title: formatMessage(emailBuilderMessages.assistantModalTemplateTab),
    },
    {
      key: 'prompt',
      title: formatMessage(emailBuilderMessages.assistantModalWritePromptTab),
    },
  ]

  const isTemplateTab = selectedIndex === 0
  const disabled = isLoading || (isTemplateTab && templateForm.prompt.trim() === '') || (!isTemplateTab && promptForm.prompt.trim() === '')

  setIsTemplateFormSelected(isTemplateTab)

  const generateCampaignTexts = async () => {
    const form = isTemplateTab ? templateForm : promptForm

    const response = await generateEmailCampaign({
      prompt: form,
      venueId,
    })

    if ('data' in response) {
      onGenerateClick(response.data)
      history.push(closeHref)
    }
  }

  return (
    <Modal ariaLabel="marketing assistant form">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(emailBuilderMessages.assistantModalTitle)}
          subTitle={<Text color="secondaryFont">{formatMessage(emailBuilderMessages.assistantFormModalSubTitle)}</Text>}
        />
      </ModalHeader>
      <ModalBody>
        <Tabs onSelect={index => setSelectedIndex(index)} selectedIndex={selectedIndex}>
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab.key} data-test={`tab-${tab.key}`}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <Template formState={templateForm} setFormState={setTemplateForm} groupVenues={groupVenues} venueUsers={venueUsers} />
          </TabPanel>
          <TabPanel>
            <Prompt formState={promptForm} setFormState={setPromptForm} venueUsers={venueUsers} />
          </TabPanel>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="generate-text-cancel">
            {formatMessage(emailBuilderMessages.sendTestEmailModalCancelButton)}
          </Button>
          <Button variant="primary" onClick={generateCampaignTexts} data-test="generate-text" disabled={disabled}>
            {isLoading ? <Loader size="xs" /> : formatMessage(emailBuilderMessages.assistantFormModalGenerateButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
