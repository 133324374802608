import type { GroupVenue, VenueUserProfile } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Input, Select } from '@sevenrooms/core/ui-kit/core'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '../../../../locales'
import { CALL_TO_ACTION_OPTIONS, PROMPT_OPTIONS, STYLE_OPTIONS, TONE_OPTIONS, PROMPT_MAPPING } from './MockOptions'
import type { TemplateForm } from './AssistantForm'

interface TemplateProps {
  formState: TemplateForm
  setFormState: React.Dispatch<React.SetStateAction<TemplateForm>>
  groupVenues: GroupVenue[]
  venueUsers: VenueUserProfile[]
}

type PromptKeys = 'thank-visit' | 'cross-promote' | 'share-details' | 'invite-guest' | 'promote-online' | 'special-promo'

export function Template({ formState, setFormState, groupVenues, venueUsers }: TemplateProps) {
  const { formatMessage } = useLocales()

  const handleChange = (field: string) => (value: string) => {
    if (field === 'prompt' && value in PROMPT_MAPPING) {
      const values = PROMPT_MAPPING[value as PromptKeys]
      setFormState(values)
    } else {
      setFormState(prevState => ({
        ...prevState,
        [field]: value,
      }))
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const groupVenuesOptions = groupVenues.map((groupVenue: GroupVenue) => ({
    id: groupVenue.id,
    label: groupVenue.name,
  }))
  const emailSenderOptions = [
    ...venueUsers.map(user => ({ id: user.fullName, label: user.fullName })),
    { id: 'venue', label: 'The Venue' },
    { id: 'custom', label: 'Custom' },
  ]
  return (
    <VStack spacing="sm" width="1120px" backgroundColor="secondaryBackground" borderColor="margin" p="m" borderRadius="s" mt="m">
      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalWriteEmail)}</Text>
        <Select
          options={PROMPT_OPTIONS}
          searchable={false}
          value={formState.prompt}
          onChange={handleChange('prompt')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalPromptPlaceholder)}
          data-test="prompt-select"
          wrapperProps={{ flex: 1 }}
        />
      </HStack>

      {formState.prompt === PROMPT_OPTIONS[1]?.id && (
        <HStack spacing="sm" alignItems="center">
          <Text>{formatMessage(emailBuilderMessages.assistantFormModalGroupVenuesLabel)}</Text>
          <Select
            options={groupVenuesOptions}
            searchable={false}
            value={formState.crossPromoteVenue}
            onChange={handleChange('crossPromoteVenue')}
            placeholder={formatMessage(emailBuilderMessages.assistantFormModalGroupVenuePlaceholder)}
            data-test="cross-promote-venue-select"
            wrapperProps={{ flex: 1 }}
            alignment="top"
          />
        </HStack>
      )}

      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalCallToActionLabel)}</Text>
        <Select
          options={CALL_TO_ACTION_OPTIONS}
          searchable={false}
          value={formState.callToAction}
          onChange={handleChange('callToAction')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalCallToActionPlaceholder)}
          data-test="call-to-action-select"
          wrapperProps={{ flex: 1 }}
        />
      </HStack>

      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalToneLabel)}</Text>
        <Select
          options={TONE_OPTIONS}
          displayValue={formState.tone || undefined}
          searchable={false}
          value={formState.tone}
          onChange={handleChange('tone')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalTonePlaceholder)}
          data-test="tone-select"
          wrapperProps={{ flex: 1 }}
          alignment="top"
        />

        <Text>{formatMessage(emailBuilderMessages.assistantFormModalStyleLabel)}</Text>
        <Select
          options={STYLE_OPTIONS}
          searchable={false}
          displayValue={formState.style || undefined}
          value={formState.style}
          onChange={handleChange('style')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalStylePlaceholder)}
          data-test="style-select"
          wrapperProps={{ flex: 1 }}
          alignment="top"
        />
      </HStack>

      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalSenderLabel)}</Text>
        <Select
          options={emailSenderOptions}
          searchable={false}
          value={formState.sender}
          onChange={handleChange('sender')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalSenderPlaceholder)}
          data-test="sender-select"
          wrapperProps={{ flex: 1 }}
          alignment="top"
        />
      </HStack>
      {formState.sender === 'custom' && (
        <HStack spacing="sm" alignItems="center">
          <Input
            name="customUser"
            fullWidth
            data-test="custom-user-input"
            value={formState.customUser}
            onChange={handleInputChange}
            placeholder={formatMessage(emailBuilderMessages.assistantFormModalInputPlaceholder)}
          />
        </HStack>
      )}
    </VStack>
  )
}
